<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Cititor" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12" sm="9" class="order-1 order-sm-0">
                  <v-row dense class="text-no-wrap" align="center">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['260'][0].a[0].val"
                        :rules="validateValue('260', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'260a ' + $t('Țară')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['260'][0].b[0].val"
                        :rules="validateValue('260', 'b')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'260b ' + $t('Stat sau provincie')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['260'][0].c[0].val"
                        :rules="validateValue('260', 'c')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'260c ' + $t('Judeţ')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['260'][0].d[0].val"
                        :rules="validateValue('260', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'260d ' + $t('Oraş')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['260'][0].e[0].val"
                        :rules="validateValue('260', 'e')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'260e ' + $t('Loc de întâlnire')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-center order-0 order-sm-1">
                  <add-image v-model="record.fields['856'][0].link" :height="150" />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                show-arrows
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Note") }}</v-tab>
                <v-tab>{{ $t("Resurse") }}</v-tab>
                <v-tab>{{ $t("Date codificate") }}</v-tab>
                <v-tab>{{ $t("Identificare") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['300'][0].a[0].val"
                        :rules="validateValue('300', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'300a ' + $t('Notă informaţională')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['330'][0].a[0].val"
                        :rules="validateValue('330', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'330a ' + $t('Notă generală')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['340'][0].a[0].val"
                        :rules="validateValue('340', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'340a ' + $t('Notă biografică')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['320'][0].a[0].val"
                        :rules="validateValue('320', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'320a ' + $t('Notă generală explicativă de referinţă')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item> </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['101'][0].a[0].val"
                        :rules="validateValue('101', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'101a ' + $t('Limba entităţii')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['101'][0].c[0].val"
                        :rules="validateValue('101', 'c')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'101c ' + $t('Limba expresiei')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['102'][0].a[0].val"
                        :rules="validateValue('102', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'102a ' + $t('Ţara de naţionalitate')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['102'][0].b[0].val"
                        :rules="validateValue('102', 'b')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'102b ' + $t('Localitate')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['801'][0].a[0].val"
                        :rules="validateValue('801', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'801a ' + $t('Țară')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['801'][0].b[0].val"
                        :rules="validateValue('801', 'b')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'801b ' + $t('Agenţie')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['801'][0].c[0].val"
                        :rules="validateValue('801', 'c')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'801c ' + $t('Data ultimei tranzacţii (înscrieri)')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['801'][0]['2'][0].val"
                        :rules="validateValue('801', '2')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'8012 ' + $t('Codul sistemului')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['810'][0].a[0].val"
                        :rules="validateValue('810', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'810a ' + $t('Citare')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['810'][0].b[0].val"
                        :rules="validateValue('810', 'b')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'810b ' + $t('Informaţia găsită')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['820'][0].a[0].val"
                        :rules="validateValue('820', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'820a ' + $t('Folosirea sau scopul informaţiei')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['640'][0].f[0].val"
                        :rules="validateValue('640', 'f')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'640f ' + $t('Data începerii sau data unică')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['640'][0].d[0].val"
                        :rules="validateValue('640', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'640d ' + $t('Oraş')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['640'][0].e[0].val"
                        :rules="validateValue('640', 'e')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'640e ' + $t('Loc de întâlnire')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['640'][0].i[0].val"
                        :rules="validateValue('640', 'i')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'640i ' + $t('Data finală')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
// import SAutocomplete from "../../../components/inputs/Autocomplete"
// import SDateTime from "../../../components/inputs/DateTime"
import AddImage from "../../../components/containers/AddImage"
import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    // SAutocomplete,
    // SDateTime,
    AddImage
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }] }],
          101: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          102: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          106: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          120: [{ ord: "", a: [{ ord: "", val: "" }] }],
          150: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          152: [{ ord: "", a: [{ ord: "", val: "" }] }],
          160: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          200: [
            {
              4: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          210: [
            {
              4: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          215: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          216: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          220: [
            {
              4: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          230: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              q: [{ ord: "", val: "" }],
              r: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              u: [{ ord: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          235: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              r: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              u: [{ ord: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          240: [
            {
              1: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          245: [
            {
              1: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          250: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          260: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              o: [{ ord: "", val: "" }]
            }
          ],
          270: [{ ord: "", a: [{ ord: "", val: "" }] }],
          280: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], t: [{ ord: "", val: "" }] }],
          305: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          310: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          320: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          340: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], p: [{ ord: "", val: "" }] }],
          341: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          356: [{ ord: "", a: [{ ord: "", val: "" }] }],
          400: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }]
            }
          ],
          410: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          415: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          416: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          417: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          420: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          430: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          440: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          443: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          445: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          447: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          448: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          449: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          450: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          453: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          455: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          457: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          458: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          459: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          460: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          470: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          471: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          472: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          473: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          474: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          480: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          488: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          499: [{ ord: "", a: [{ ord: "", val: "" }] }],
          500: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }]
            }
          ],
          510: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          515: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          516: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          517: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          520: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          530: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          540: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          543: [{ 8: [{ ord: "", val: "" }], ord: "", ind2: "" }],
          545: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          550: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          560: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          580: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          599: [{ ord: "", a: [{ ord: "", val: "" }] }],
          600: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          601: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          605: [{ ord: "", a: [{ ord: "", val: "" }] }],
          606: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          607: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          608: [{ ord: "", a: [{ ord: "", val: "" }] }],
          615: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }], n: [{ ord: "", val: "" }] }],
          640: [
            {
              0: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }]
            }
          ],
          675: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          676: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          680: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          686: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          700: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          710: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          715: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          716: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          717: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          720: [{ 3: [{ ord: "", val: "" }], 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          730: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          740: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          743: [{ 3: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          745: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          750: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          760: [{ 3: [{ ord: "", val: "" }], ord: "", ind2: "", ind1: "", a: [{ ord: "", val: "" }] }],
          780: [{ 3: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }]
            }
          ],
          810: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          815: [{ ord: "", a: [{ ord: "", val: "" }] }],
          820: [{ ord: "", a: [{ ord: "", val: "" }] }],
          825: [{ ord: "", a: [{ ord: "", val: "" }] }],
          830: [{ ord: "", a: [{ ord: "", val: "" }] }],
          835: [{ ord: "", a: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }] }],
          836: [{ ord: "", d: [{ ord: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          886: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          920: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          940: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }]
            }
          ],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "015": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "035": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "036": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          "050": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "051": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "061": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }] }],
          101: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          102: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          106: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          120: [{ ord: "", a: [{ ord: "", val: "" }] }],
          150: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          152: [{ ord: "", a: [{ ord: "", val: "" }] }],
          160: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          200: [
            {
              4: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          210: [
            {
              4: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          215: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          216: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          220: [
            {
              4: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          230: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              q: [{ ord: "", val: "" }],
              r: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              u: [{ ord: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          235: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              r: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              u: [{ ord: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          240: [
            {
              1: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          245: [
            {
              1: [{ ord: "", val: "" }],
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          250: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          260: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              o: [{ ord: "", val: "" }]
            }
          ],
          270: [{ ord: "", a: [{ ord: "", val: "" }] }],
          280: [
            {
              7: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], t: [{ ord: "", val: "" }] }],
          305: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          310: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          320: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          340: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], p: [{ ord: "", val: "" }] }],
          341: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          356: [{ ord: "", a: [{ ord: "", val: "" }] }],
          400: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }]
            }
          ],
          410: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          415: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          416: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          417: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          420: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          430: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          440: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          443: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          445: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          447: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          448: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          449: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          450: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          453: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          455: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          457: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          458: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          459: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          460: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          470: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          471: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          472: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          473: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          474: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          480: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          488: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          499: [{ ord: "", a: [{ ord: "", val: "" }] }],
          500: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }]
            }
          ],
          510: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          515: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          516: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          517: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          520: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          530: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          540: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          543: [{ 8: [{ ord: "", val: "" }], ord: "", ind2: "" }],
          545: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          550: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          560: [
            {
              3: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          580: [{ 3: [{ ord: "", val: "" }], 8: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          599: [{ ord: "", a: [{ ord: "", val: "" }] }],
          600: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          601: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          605: [{ ord: "", a: [{ ord: "", val: "" }] }],
          606: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          607: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          608: [{ ord: "", a: [{ ord: "", val: "" }] }],
          615: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }], n: [{ ord: "", val: "" }] }],
          640: [
            {
              0: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }]
            }
          ],
          675: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          676: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          680: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          686: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          700: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              8: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          710: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }]
            }
          ],
          715: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          716: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          717: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          720: [{ 3: [{ ord: "", val: "" }], 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          730: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          740: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          743: [{ 3: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          745: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          750: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          760: [{ 3: [{ ord: "", val: "" }], ord: "", ind2: "", ind1: "", a: [{ ord: "", val: "" }] }],
          780: [{ 3: [{ ord: "", val: "" }], ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }]
            }
          ],
          810: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          815: [{ ord: "", a: [{ ord: "", val: "" }] }],
          820: [{ ord: "", a: [{ ord: "", val: "" }] }],
          825: [{ ord: "", a: [{ ord: "", val: "" }] }],
          830: [{ ord: "", a: [{ ord: "", val: "" }] }],
          835: [{ ord: "", a: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }] }],
          836: [{ ord: "", d: [{ ord: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          886: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          920: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          940: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }]
            }
          ],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "015": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "035": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "036": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          "050": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "051": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "061": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: []
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
